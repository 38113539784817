@use '../../styles/mixins' as mixins;

.homepage-ribbon-block {
	
	background-color: var(--wp--preset--color--bnpp-green);
	padding: var(--wp--preset--spacing--30) 0;
	position: relative;
	z-index: 2;

	&.hidden {
		display: none;
	}

	.content-col {

		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: var(--wp--preset--spacing--60);
		position: relative;
		z-index: 2;

		.content {
			display: flex;
			align-items: center;
			gap: var(--wp--preset--spacing--20) var(--wp--preset--spacing--60);
			flex-wrap: wrap;
	
			p {
				color: var(--wp--preset--color--white);
				margin: 0;
			}
		}

		.close {
			--iconSize: 40px;
			width: var(--iconSize);
			height: var(--iconSize);
			position: relative;
			cursor: pointer;

			&::before, &::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 2px;
				background-color: var(--wp--preset--color--white);
			}

			&::before {
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&::after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: min(300px, 25%);
		height: 100%;
		background-color: rgb(101 165 136);
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 30% 100%);
    	z-index: 1;
	}
	
	.bnpp-button {
		background-color: #0C272866;
		line-height: 1;

		&:hover {
			background-color: #004E31;
		}
	}

}